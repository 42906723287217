import React from "react";
import { ActionButton } from "./ActionButton";
import { CenterLoader } from "../Deprecated/CenterLoader.jsx";

/**
 * @function FormButtons
 * @description
 * Form buttons for the form, includes cancel and submit buttons.
 * Can also handle step state for multi-step forms.
 * @param {boolean} props.isCreate - Is this a create form
 * @param {boolean} props.allowSubmit - Is the form allowed to submit
 * @param {string} props.text - Text to display on the submit button
 * @param {string} props.cancelText - Text to display on the cancel button
 * @param {boolean} props.loaderVisible - Is the loader visible
 * @param {string} props.theme - Theme to apply to the buttons
 * @param {CallableFunction} props.actionOnCancel - Function to call on cancel
 * @param {CallableFunction} [props.onSubmit] - Function to call on submit
 * @param {Boolean} [props.hideSubmit] - Hide the submit button
 * @param {Object} [props.stepState] - {
 *     isLastStep: boolean,
 *     onPreviousStepSubmit: CallableFunction
 * }
 * @return {React.JSX.Element}
 * @constructor
 */
export const FormButtons = (props) => {
    const {
        actionOnCancel,
        loaderVisible,
        stepState,
        cancelText,
        hideSubmit = false,
    } = props;

    return !loaderVisible ? (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "10px",
            }}
        >
            {/** If stepState is provided and last step is false, display the previous button **/}
            {stepState && stepState.currentStep !== 0 ? (
                <PreviousButton {...stepState} />
            ) : (
                <ActionButton
                    themeColor={"primary"}
                    onClick={actionOnCancel}
                    text={cancelText ?? "Cancel"}
                    style={{ width: "100px", margin: "10px" }}
                />
            )}
            {/** If stepState is not present, show normal submit button **/}
            {stepState === undefined && !hideSubmit && (
                <SubmitButton {...props} />
            )}
            {/** If stepState is present and is on last step, show normal submit button **/}
            {stepState && stepState.isLastStep && !hideSubmit && (
                <SubmitButton {...props} />
            )}
            {/** If stepState is present, and is not on last step, show next button **/}
            {stepState && stepState.isLastStep === false && <NextButton />}
        </div>
    ) : (
        <div>
            <CenterLoader />
        </div>
    );
};

const SubmitButton = (props) => {
    const { theme, isCreate, text, allowSubmit, icon } = props;

    return (
        <ActionButton
            themeColor={theme ?? (isCreate ? "success" : "info")}
            text={text ?? (isCreate ? "Create" : "Update")}
            icon={
                icon ? icon : isCreate ? "k-icon k-i-plus" : "k-icon k-i-save"
            }
            disabled={!allowSubmit}
            disabledToolTipMessage={"Please fill out all required fields"}
            style={{ minWidth: "100px", margin: "10px" }}
            onClick={props.onSubmit}
        />
    );
};

const NextButton = () => {
    return (
        <ActionButton
            themeColor={"primary"}
            text={"Next"}
            style={{ width: "100px", margin: "10px" }}
            icon={"k-icon k-i-redo"}
        />
    );
};

const PreviousButton = (props) => {
    const { onPreviousStepSubmit } = props;

    return (
        <ActionButton
            onClick={onPreviousStepSubmit}
            themeColor={"primary"}
            text={"Previous"}
            style={{ width: "100px", margin: "10px" }}
            icon={"k-icon k-i-undo"}
        />
    );
};
