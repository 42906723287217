import React, { useContext, useState } from "react";
import { DataGrid } from "../../../components/Grids/DataGrid";
import { GridColumn } from "@progress/kendo-react-grid";
import { LinkCell } from "../../../components/GridColumnComponents/LinkCell";
import { EnumCell } from "../../../components/GridColumnComponents/EnumCell";
import { CustomerTypeEnum } from "../../../resources/Enums/CustomerStatusEnum";
import { ActionCell } from "../../../components/GridColumnComponents/ActionCell";
import { PermissionsEnum } from "../../../resources/Enums/PermissionsEnum";
import { useModal } from "../../../hooks/useModal";
import BaseService from "../../../services/BaseService";
import { ReloadDataContext } from "../../../providers/ReloadDataProvider";
import { LeadModal } from "./Components/LeadModal";
import { ActionButton } from "../../../components/Buttons/ActionButton";
import { PreCreateLeadModal } from "./Components/PreCreateLeadModal";
import LeadService from "../../../services/LeadService";
import { LeadHoldUntilModal } from "./Components/LeadHoldUntilModal";
import { ToastContext } from "../../../providers/ToastProvider";
import { BreadcrumbContext } from "../../../providers/BreadCrumbProvider";
import { EventModal } from "../CalendarEvents/Components/EventModal/EventModal";
import JobModal from "../Jobs/Components/JobModal.jsx";

const LeadDashboardPage = () => {
    const leadService = new LeadService("Customer/Lead");
    const { triggerReload } = useContext(ReloadDataContext);
    const { showToast } = useContext(ToastContext);
    const { handleRouteChange } = useContext(BreadcrumbContext);
    const leadModal = useModal();
    const leadHoldUntilModal = useModal();
    const preCreateLeadModal = useModal();
    const appointmentModal = useModal();
    const jobModal = useModal();
    const [inConvertToCustomerEdit, setInConvertToCustomerEdit] =
        useState(false);

    const onLeadDelete = (id) => {
        const service = new BaseService("Customer/Lead");
        service.delete(id).then(() => {
            triggerReload();
            leadModal.close();
        });
    };

    const onMarkDead = (id) => {
        const service = new LeadService("Customer/Lead");
        service.updateStatus(id, 0).then(() => {
            triggerReload();
        });
    };

    const startMarkHold = (id) => {
        leadHoldUntilModal.open(id);
    };

    const convertToCustomer = (dataItem, isResubmission = false) => {
        if (!leadNeedsAdditionalInfo(dataItem)) {
            leadService.convertToCustomer(dataItem.id).then((res) => {
                handleRouteChange({
                    uri: `/customer/${res.data.id}`,
                    breadcrumbInfo: [
                        {
                            id: res.data.id,
                            text: dataItem.combinedName,
                        },
                    ],
                });
            });
        } else if (isResubmission) {
            // throw missing info still
            showToast(
                "Lead still missing information, conversion cancelled",
                "error"
            );
        } else {
            setInConvertToCustomerEdit(true);
            leadModal.open(dataItem.id);
        }
    };

    const leadNeedsAdditionalInfo = (dataItem) => {
        const hasName = dataItem.name1 && dataItem.primaryContact?.name1;
        const hasEmail = dataItem.primaryContact?.email;
        const hasPhoneNumber = dataItem.primaryContact?.phone?.phoneNumber;
        const hasAddress = dataItem.address?.address1;

        return !(hasName && hasEmail && hasPhoneNumber && hasAddress);
    };

    // Allows the lead modal to close the pre-create lead modal
    const handleLeadModalClose = (action) => {
        if (action === "CREATE") {
            preCreateLeadModal.close();
        }

        if (action === "EDIT" && inConvertToCustomerEdit) {
            leadService.get(leadModal.id).then((res) => {
                setInConvertToCustomerEdit(false);
                convertToCustomer(res.data, true);
            });
        }

        leadModal.close();
    };

    const onAppointmentCreationSuccess = (lead) => {
        convertToCustomer(lead);
    };

    const onAppointmentClick = (dataItem) => {
        if (leadNeedsAdditionalInfo(dataItem)) {
            showToast(
                "Lead is missing information, please fill out before making an appointment",
                "error"
            );
            return;
        }

        const customer = {
            ...dataItem.customer,
            address: dataItem.address,
            primaryContact: dataItem.primaryContact,
        };

        const onJobSuccess = () => {
            jobModal.close();
            appointmentModal.open(
                undefined,
                {
                    eventDate: new Date(),
                    customer,
                },
                () => onAppointmentCreationSuccess(dataItem)
            );
        };

        jobModal.open(undefined, { customer }, onJobSuccess);
    };

    return (
        <div className={"JustifyLeftAlignLeft FlexColumn MediumGap"}>
            <ActionButton
                text={"Create Lead"}
                icon={"k-icon k-i-plus"}
                onClick={() => preCreateLeadModal.open()}
                requiredPermissions={[PermissionsEnum.CreateLead]}
            />
            <PreCreateLeadModal {...preCreateLeadModal} leadModal={leadModal} />
            <LeadModal {...leadModal} close={handleLeadModalClose} />
            <LeadHoldUntilModal {...leadHoldUntilModal} />
            <EventModal {...appointmentModal} />
            <JobModal {...jobModal} />
            <DataGrid
                sortable={false}
                uri={"/Customer/Lead/GetAll?"}
                initialFilter={{
                    logic: "and",
                    filters: [
                        {
                            field: "OnHold.Value",
                            operator: "eq",
                            value: "false",
                        },
                        {
                            field: "IsDead.Value",
                            operator: "eq",
                            value: "false",
                        },
                    ],
                }}
                initialSort={[{ field: "customer.name2", dir: "asc" }]}
                searchFields={["customer.name1", "customer.name2"]}
            >
                <GridColumn
                    field={"combinedName"}
                    title={"Name"}
                    width={300}
                    cell={(props) => (
                        <LinkCell
                            {...props}
                            uri={`/Lead/${props.dataItem.id}`}
                        />
                    )}
                />
                <GridColumn
                    field={"type"}
                    title={"Type"}
                    width={150}
                    cell={(props) => (
                        <EnumCell {...props} enumerable={CustomerTypeEnum} />
                    )}
                />
                <GridColumn
                    field={"primaryContact.email"}
                    title={"Email"}
                    width={250}
                    cell={(props) => (
                        <LinkCell
                            {...props}
                            uri={`mailto:${props.dataItem?.primaryContact?.email}`}
                            text={props.dataItem?.primaryContact?.email}
                        />
                    )}
                />
                <GridColumn
                    field={"primaryContact.phone.phoneNumber"}
                    title={"Phone"}
                    width={150}
                    cell={(props) => (
                        <LinkCell
                            {...props}
                            uri={`tel:${props.dataItem?.phone?.phoneNumber}`}
                            text={props.dataItem?.phone?.phoneNumber}
                        />
                    )}
                />
                <GridColumn
                    width={600}
                    cell={(props) => (
                        <ActionCell
                            onDelete={() => onLeadDelete(props.dataItem.id)}
                            permissions={{
                                delete: [PermissionsEnum.DeleteLead],
                            }}
                            additionalActions={[
                                {
                                    onClick: () =>
                                        startMarkHold(props.dataItem.id),
                                    text: "Mark as Hold",
                                    requiredPermissions: [
                                        PermissionsEnum.UpdateLead,
                                    ],
                                },
                                {
                                    onClick: () =>
                                        onMarkDead(props.dataItem.id),
                                    text: "Mark as Dead",
                                    confirmationState: {
                                        needsConfirmation: true,
                                        confirmationText:
                                            "Are you sure you want to mark this lead as dead?",
                                    },
                                    requiredPermissions: [
                                        PermissionsEnum.UpdateLead,
                                    ],
                                },
                                {
                                    onClick: () =>
                                        convertToCustomer(props.dataItem),
                                    text: "Convert to Customer",
                                    requiredPermissions: [
                                        PermissionsEnum.UpdateLead,
                                    ],
                                    // show confirmation only if we aren't going to show a modal to get more info
                                    confirmationState: {
                                        needsConfirmation: true,
                                        confirmationText:
                                            !leadNeedsAdditionalInfo(
                                                props.dataItem
                                            )
                                                ? "This lead has all required information. Are you sure you want to Convert this lead?"
                                                : "This lead has missing information. Please fill out and try again.",
                                        confirmationButtonText:
                                            !leadNeedsAdditionalInfo(
                                                props.dataItem
                                            )
                                                ? "Yes"
                                                : "Edit Lead",
                                        confirmationButtonThemeColor: "success",
                                        cancelButtonText:
                                            !leadNeedsAdditionalInfo(
                                                props.dataItem
                                            )
                                                ? "No"
                                                : "Cancel",
                                    },
                                },
                                {
                                    onClick: () =>
                                        onAppointmentClick(props.dataItem),
                                    text: "Make Appointment",
                                    requiredPermissions: [
                                        PermissionsEnum.CreateCalendarEvent,
                                    ],
                                },
                            ]}
                        />
                    )}
                />
            </DataGrid>
        </div>
    );
};

export default LeadDashboardPage;
